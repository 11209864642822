import { motion } from "framer-motion";

export default function Computers() {
  return (
    <motion.svg
      id="uuid-f7a4c675-463b-4929-aeec-d2dc590b7a4b"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 110.17 186.36"
      initial={{ y: -100, opacity: 1 }} // Initial position above and hidden
      animate={{ y: 0, opacity: 1 }} // Animation to drop in and become fully visible
      transition={{ type: "spring", stiffness: 500 }}
    >
      <defs>
        <clipPath id="uuid-77042d30-7af6-4372-8925-7cb7ac4b936e">
          <rect
            x="40.32"
            y="63.52"
            width="56.66"
            height="43.13"
            rx="8.55"
            ry="8.55"
            transform="translate(9.69 -6.98) rotate(6.26)"
            fill="none"
            strokeWidth="0"
          />
        </clipPath>
      </defs>
      <path
        d="m103.93,15.17h0c-.22-.26-.45-.51-.71-.73l-11.01-10.03-10.05,7.86-39.49-1.39c-3.5-.12-6.47,2.64-6.59,6.14l-1.04,29.47-9.33,4.93,10.18,11.87c.31.41.68.78,1.08,1.1h0c1.04.84,2.33,1.36,3.76,1.41l56.61,2c3.5.12,6.47-2.64,6.59-6.14l1.49-42.19c.06-1.63-.51-3.14-1.49-4.3Z"
        fill="#f6915e"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <rect
        x="24.88"
        y="1.16"
        width="69.39"
        height="54.97"
        rx="8.55"
        ry="8.55"
        transform="translate(1.05 -2.08) rotate(2.02)"
        fill="#f1e6d8"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <line
        x1="93.44"
        y1="50.11"
        x2="103.93"
        y2="61.66"
        fill="#f1e6d8"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <line
        x1="94.84"
        y1="9.49"
        x2="105.42"
        y2="19.47"
        fill="#f1e6d8"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <line
        x1="85.46"
        y1="57.03"
        x2="97.34"
        y2="67.81"
        fill="#f1e6d8"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <line
        x1="31.6"
        y1="55.11"
        x2="40.73"
        y2="65.81"
        fill="#f1e6d8"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <path
        d="m83.7,120.2l13.17-8.85-9.81-8.8,2.63-23.96c.38-3.48-2.16-6.65-5.64-7.03l-33.25-3.65-8.49-9.84-15.32,8.19c-1.93.92-3.36,2.78-3.61,5.05l-4.15,37.81c-.38,3.48,2.16,6.65,5.64,7.03l53.64,5.88c.99.11,1.94-.02,2.82-.34h0c.89-.32,1.7-.83,2.36-1.49Z"
        fill="#f6915e"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <line
        x1="37.7"
        y1="64.02"
        x2="23.39"
        y2="71.31"
        fill="#f1e6d8"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <line
        x1="34.16"
        y1="100.11"
        x2="19.24"
        y2="109.11"
        fill="#f1e6d8"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <line
        x1="40.29"
        y1="106.84"
        x2="24.88"
        y2="116.14"
        fill="#f1e6d8"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <line
        x1="91.9"
        y1="112.92"
        x2="78.52"
        y2="122.03"
        fill="#f1e6d8"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <rect
        x="29.07"
        y="4.69"
        width="60.47"
        height="47.89"
        rx="8.55"
        ry="8.55"
        transform="translate(1.05 -2.07) rotate(2.02)"
        fill="#fff"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <path
        d="m26.98,52.91s-12.95,3.99-14.9,7.55c-1.95,3.56,2.51,5.61,0,10.85-2.51,5.24-9.75,10.74-8.08,13.98,1.67,3.24,6.68,11.87,6.13,16.6"
        fill="none"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <rect
        x="7.63"
        y="101.91"
        width="6.4"
        height="4.79"
        rx="2"
        ry="2"
        transform="translate(-61.41 28.3) rotate(-37.62)"
        fill="#f1e6d8"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <polyline
        points="12.94 109.72 10.94 107.25 13.65 105.16 15.28 107.44"
        fill="none"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <path
        d="m81.29,164.54s5.5,1.78,5.74,3.81c.24,2.03-2.74,4.17,0,4.91,2.74.74,6.72-2.41,11.69,0,4.97,2.41,6.71,6.87,6.71,6.87"
        fill="none"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <path
        d="m32.3,120.14s-3.93,12.19-11.42,15-11.39,6.71-8.88,7.82c2.51,1.11,5.57,6.63,3.62,7.63s-6.68,2.67-6.68,2.67"
        fill="none"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <rect
        x="1.83"
        y="152.42"
        width="7.1"
        height="4.32"
        rx="1.82"
        ry="1.82"
        transform="translate(-51.16 10.61) rotate(-19.45)"
        fill="#f1e6d8"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <line
        x1="1.81"
        y1="155.14"
        x2=".09"
        y2="155.8"
        fill="none"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <line
        x1="2.26"
        y1="156.39"
        x2=".72"
        y2="156.98"
        fill="none"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <rect
        x="104.43"
        y="179.75"
        width="4.86"
        height="4.86"
        rx="2"
        ry="2"
        transform="translate(-83.15 89.95) rotate(-33.71)"
        fill="#f1e6d8"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <line
        x1="107.42"
        y1="184.72"
        x2="108.39"
        y2="186.23"
        fill="none"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <line
        x1="108.98"
        y1="183.68"
        x2="109.96"
        y2="185.11"
        fill="none"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <circle
        cx="60.75"
        cy="27.57"
        r="18.8"
        fill="#85a5d6"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <path
        d="m60.75,11.04s1.95,3.55,3.3,4.39.84,4.91-.94,4.91-1.78-2.3-2.82-2.09-5.85,3.76-4.91,5.53c.94,1.78,3.47,1.98,4.66,3.13s3.38,2.51,1.4,3.34-5.46-.52-6.18,1.98c-.71,2.51,1.8,6.37,4.04,6.79s2.97,2.71,4.12,3.86c1.15,1.15,1.39,3.03,1.39,3.03,0,0,4.48-4.18,3.84-6.79-.64-2.61-3.26-4.91-2.99-5.85s3.71-5.62,6.74-4.64c3.03.98,4.97,1.06,6.06,0s1.08-2.14,1.08-2.14c0,0-.46-10.34-8.45-14.62-7.98-4.28-9.47-1.67-10.35-.84Z"
        fill="#a3c872"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <path
        d="m46.53,15.27s4.57,10.65,3.21,12.3-2.71,1.02-3.97,1.54-1.78.94-1.36,2.19c.42,1.25,2.61,3.34,1.36,2.82s-3.17-1.63-3.17-1.63c0,0-3.14-8.5,3.93-17.22Z"
        fill="#a3c872"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <rect
        x="35.3"
        y="59.9"
        width="66.71"
        height="50.78"
        rx="8.55"
        ry="8.55"
        transform="translate(9.71 -6.98) rotate(6.26)"
        fill="#f1e6d8"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <rect
        x="40.32"
        y="63.72"
        width="56.66"
        height="43.13"
        rx="8.55"
        ry="8.55"
        transform="translate(9.71 -6.98) rotate(6.26)"
        fill="#fff"
        strokeWidth="0"
      />
      <path
        d="m97.96,158.41l-2.35-36.09c-.1-1.53-.74-2.91-1.72-3.95h0c-.64-.68-1.43-1.21-2.3-1.56l-9.96-5.69-5.71,6.09-39.06,2.54c-3.5.23-6.17,3.28-5.95,6.77l1.75,26.91-7.36,5.54,9.12,7.3c.5.37,3.41,2.43,5.61,2.29l51.98-3.38c3.5-.23,6.17-3.28,5.95-6.77Z"
        fill="#f6915e"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <path
        d="m42.71,167.05v1.2c0,2.13,8.62,3.89,19.27,3.92,10.65.03,19.29-1.67,19.29-3.81v-3.82s-38.56,2.51-38.56,2.51Z"
        fill="#f6915e"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <line
        x1="85.21"
        y1="115.46"
        x2="95.61"
        y2="122.33"
        fill="#f6915e"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <line
        x1="86.99"
        y1="151.17"
        x2="97.96"
        y2="158.41"
        fill="#f6915e"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <line
        x1="81.04"
        y1="157.94"
        x2="92.01"
        y2="165.19"
        fill="#f6915e"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <line
        x1="31.25"
        y1="161.3"
        x2="40.03"
        y2="168.57"
        fill="#f6915e"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <rect
        x="22.05"
        y="111.44"
        width="64.84"
        height="48.91"
        rx="8.55"
        ry="8.55"
        transform="translate(-8.7 3.82) rotate(-3.72)"
        fill="#f1e6d8"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <rect
        x="26.18"
        y="114.65"
        width="56.56"
        height="42.67"
        rx="8.55"
        ry="8.55"
        transform="translate(-8.71 3.82) rotate(-3.72)"
        fill="#fff"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <text
        transform="translate(33.53 136.24) rotate(-3.72)"
        fill="#2d1d16"
        fontFamily="Tiny5-Regular, Tiny5"
        fontSize="7.45"
        letterSpacing=".06em"
      >
        <tspan x="0" y="0">
          LOADING...
        </tspan>
      </text>
      <rect
        x="32.21"
        y="137.97"
        width="44.52"
        height="5.04"
        rx="2.13"
        ry="2.13"
        transform="translate(-9 3.83) rotate(-3.72)"
        fill="#f1e6d8"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <line
        x1="34.61"
        y1="139.26"
        x2="34.93"
        y2="144.29"
        fill="#f1e6d8"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".3"
      />
      <line
        x1="36.2"
        y1="139.26"
        x2="36.54"
        y2="144.19"
        fill="#f1e6d8"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".3"
      />
      <line
        x1="37.83"
        y1="144.1"
        x2="37.43"
        y2="139.08"
        fill="#f1e6d8"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".3"
      />
      <line
        x1="38.86"
        y1="138.98"
        x2="39.25"
        y2="144.1"
        fill="#f1e6d8"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".3"
      />
      <line
        x1="41.1"
        y1="143.89"
        x2="40.72"
        y2="138.86"
        fill="#f1e6d8"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".3"
      />
      <line
        x1="42.96"
        y1="138.72"
        x2="43.39"
        y2="143.74"
        fill="#f1e6d8"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".3"
      />
      <line
        x1="33.31"
        y1="139.73"
        x2="33.63"
        y2="143.89"
        fill="#f1e6d8"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".3"
      />
      <line
        x1="45.38"
        y1="138.56"
        x2="45.81"
        y2="143.58"
        fill="#f1e6d8"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".3"
      />
      <line
        x1="48.33"
        y1="138.37"
        x2="48.76"
        y2="143.39"
        fill="#f1e6d8"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".3"
      />
      <line
        x1="51.42"
        y1="138.17"
        x2="51.85"
        y2="143.19"
        fill="#f1e6d8"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".3"
      />
      <line
        x1="55.04"
        y1="137.93"
        x2="55.51"
        y2="142.95"
        fill="#f1e6d8"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".3"
      />
      <line
        x1="59.26"
        y1="137.66"
        x2="59.71"
        y2="142.68"
        fill="#f1e6d8"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".3"
      />
      <line
        x1="64.88"
        y1="137.29"
        x2="65.27"
        y2="142.32"
        fill="#f1e6d8"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".3"
      />
      <g clipPath="url(#uuid-77042d30-7af6-4372-8925-7cb7ac4b936e)">
        <path
          d="m45.96,105.06l40.49,4.78c4.81.57,9.21-2.9,9.78-7.71l3.15-26.65c.13-1.12.04-2.21-.23-3.23-2.1.78-4.61,2.11-7.9,3.57-7.3,3.24-24.82,4.75-27.47,4.73s-7.6-.06-7.92-1.68c-.26-1.32-10.47-6.13-15.08-5.13l-2.55,21.54c-.57,4.81,2.9,9.21,7.72,9.78Z"
          fill="#85a5d6"
          stroke="#2d1d16"
          strokeMiterlimit="10"
          strokeWidth=".5"
        />
        <path
          d="m86.4,109.83c4.81.57,9.21-2.9,9.78-7.71l1.77-14.96c-1.78-.13-3.16-.23-3.93-.26-4.29-.21-13.21-1.26-15.53-.13s-10.91,1.08-13.88-.12c-2.96-1.19-20.81-.99-23.12-1.53-.54-.12-1.24-.13-2.05-.02l-1.2,10.17c-.57,4.81,2.9,9.21,7.71,9.78l40.44,4.78Z"
          fill="#7291ca"
          stroke="#2d1d16"
          strokeMiterlimit="10"
          strokeWidth=".5"
        />
        <path
          d="m96.15,102.53l.86-7.31c-5.09.29-9.74.66-10.95,1.05-2.98.97-10.94,3.87-13.25,3.52-2.31-.35-15.49-4.76-20.95-4.47-5.04.26-10.78,1.38-13.71,1.4,0,4.35,3.26,8.15,7.66,8.72,5.44-.54,10.26-.52,12.4.84.41.26.82.52,1.22.78l26.93,3.18c4.81.57,9.21-2.9,9.78-7.71Z"
          fill="#85a5d6"
          stroke="#2d1d16"
          strokeMiterlimit="10"
          strokeWidth=".5"
        />
      </g>
      <rect
        x="40.18"
        y="63.52"
        width="56.66"
        height="43.13"
        rx="8.55"
        ry="8.55"
        transform="translate(9.69 -6.96) rotate(6.26)"
        fill="none"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
    </motion.svg>
  );
}
