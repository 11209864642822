export default function Squares() {
  return (
    <svg
      id="uuid-9ce677ea-0273-4a67-b112-3b165f15c171"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 31.7 6.77"
    >
      <line
        x1="25.18"
        y1=".18"
        x2="31.52"
        y2="6.52"
        fill="#f1e6d8"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <line
        x1="25.17"
        y1="6.52"
        x2="31.52"
        y2=".18"
        fill="#f1e6d8"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <rect
        x="13.95"
        y=".36"
        width="6.16"
        height="6.16"
        fill="#f1e6d8"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <line
        y1="6.52"
        x2="7.7"
        y2="6.52"
        fill="#f1e6d8"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
    </svg>
  );
}
