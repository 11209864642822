import { motion } from "framer-motion";

export default function CloudOne({ isLoaded }) {
  return (
    <motion.svg
      id="uuid-f0e916ea-c3e7-428d-a18e-bf0e05e3d197"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 117.91 41.87"
      initial={{ x: "-100vw" }} // Initial position outside the left edge and hidden
      animate={isLoaded ? { x: "0vw" } : {}}
      transition={{ duration: 1.2, delay: 0 }}
    >
      <path
        d="m117.66,31.31c-.09,2.47-6.53,1.46-10.81,1.2s-13.67-2.31-16.21-.94-6.04,3.62-9.5,4.95c-3.45,1.33-8.62,5.63-13.19,5.05s-8.61-4.8-12.07-3.47c-3.45,1.33-7.27,3.59-9.64,2.21s-7.41-4.44-9.98-2.45-6.59,2.46-9.3,1.64c-2.71-.82-2.89-2.29-7.76-1.65-4.87.63-6.66-.28-6.92-1.67-.26-1.4-2.24-4.85-3.5-5.04s-6.93-1.45-7.45-2.51c-.51-1.07-2.03-4.61-.25-7.49l1.78-2.89s1.94-1.61,5.74-1.73,5.04-1.54,5.11-2.69c.07-1.15.82-6.05,3.45-7.74,2.63-1.69,10.02-4.13,11.67-2.76,1.65,1.36.33,4.16,2.42,3.83,2.09-.34,11.62-5.76,14.87-6.26s9.06-1.42,11.92.7c2.87,2.13,1.75,5.4.54,6.36-1.21.96-1.57,6.12,1.15,6.28,2.72.16,11.19-.95,14.32.62s3.94,7.25,7.09,8.47,19.32-1.73,22.68-.27,13.93,5,13.82,8.26Z"
        fill="#fff"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".8"
      />
    </motion.svg>
  );
}

export function CloudTwo({ isLoaded }) {
  return (
    <motion.svg
      id="uuid-995e738e-5777-4cfd-8644-c81329b9cad3"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 153.75 55.6"
      initial={{ x: "-100vw", opacity: 1 }} // Initial position outside the left edge and hidden
      animate={isLoaded ? { x: "0vw", opacity: 1 } : {}} // Animation to move across the screen and become visible
      transition={{ duration: 2, delay: 0 }}
    >
      <path
        d="m153.47,47.69c.76,2.65-11.75.05-16.48.84-4.73.79-21.09,4.01-24.76,4.01-3.67,0-6.53-2.11-11.61-.81-5.07,1.29-15.61,3.48-21.84,3.62s-13.12-1.77-19.32-.97c-6.2.8-14.42,1.58-15.97.01-1.55-1.57-2.74-3.37-10.78-2.67s-14.87-.06-19.83-.18-9.41-.99-10.65-3.68c-1.24-2.69-3.49-7.35-.41-8.06,3.08-.71,12.39-.84,11.98-3.93-.41-3.09-5.1-5.05-2.51-8.31,2.59-3.25,5.07-5.03,8.72-5.4,3.65-.37,7.28-4.93,10.21-5s7.23,1.36,6.64-3.01c-.59-4.38-2.56-7.03-.18-7.15l4.58-.23s-.69-2.72,1.51-2.83,4.91-.98,4.65-2.44c-.26-1.46,5.7.26,7.48,2.74s9.26-1.94,11.99-2.45,8.9-1.92,10.57-1.46c1.68.47,6.01,2.63,7.84,2.54s3.94,1.64,3.44,2.76-3.84,3.87-1.18,5.75c2.67,1.88,2.88,6.09,2.4,7.4-.48,1.31,11.27,3.77,11.66,5.46s4.59,3.57,4.68,5.43-.9,3.93,2.8,4.47,9.52,3.15,11.92,3.37,14.38-.48,19.1,1.11,12.69,6.7,13.36,9.05Z"
        fill="#fff"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".8"
      />
    </motion.svg>
  );
}

export function CloudThree({ isLoaded }) {
  return (
    <motion.svg
      id="uuid-05ad10f7-3a68-40c1-aeef-cda976b3b752"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 104.9 41.92"
      initial={{ x: "-100vw", opacity: 1 }}
      animate={isLoaded ? { x: "0vw", opacity: 1 } : {}}
      transition={{ duration: 2, delay: 2 }}
    >
      <path
        d="m.25,35.63c-.28,4.22,15.68,6.01,19.3,6.02s28.95.3,35.28-3.01,17.79,1.21,33.78-1.5,16.28-3.92,15.98-7.24c-.3-3.32-22.62-2.11-21.71-4.83.9-2.71-.3-4.83-7.54-5.13-7.24-.3-13.57-2.41-13.57-4.22s-4.77-7.54-9.32-7.54-8.47.9-6.96-1.81c1.51-2.71-3.62-8.44-6.94-5.13-3.32,3.32-9.65,1.81-10.55,6.03-.9,4.22-15.08,4.52-16.28,8.44-1.21,3.92,2.41,10.86,6.63,10.86s-13.87.9-15.98,3.32c-2.11,2.41-1.81,1.21-2.11,5.73Z"
        fill="#fff"
        stroke="#2d1d16"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
    </motion.svg>
  );
}
